<template>
  <v-dialog
    class="dialog"
    v-model="active"
    @click:outside="close('abort')"
    persistent
    width="750px"
    max-width="750px"
    overlay-color="#0253B3"
    overlay-opacity="0.3"
  >
    <v-card flat class="rounded-lg wrap onboarding-dialog">
      <p class="text-left font-weight-bold mb-3 font-size-16 header mb-5">
        Select Soft Skills and Expertise Level
      </p>

      <v-form v-model="formValid" @submit.prevent="save">
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.admin_cms_id"
              :items="
                $store.getters['admin/cmsLists']['soft_skills'].items.map(
                  (item) => {
                    return {
                      value: item.id,
                      text: item[this.$i18n.locale], // Fallback to item name if translation is not available
                    };
                  }
                )
              "
              label="Soft Skills"
              :rules="[validations.required]"
              outlined
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-rating
              v-model="form.rating"
              :items="expertiseLevels"
              label="Expertise Level"
              :rules="[validations.required]"
            ></v-rating>
          </v-col>
        </v-row>

        <ResponseAlert :response="formResponse"></ResponseAlert>

        <v-card-actions class="no-gutters pa-0 ma-0 mt-3">
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                @click="close"
                height="58"
                class="full-w mt-5 font-weight-medium "
                color="#fff"
                light
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                :loading="formLoading"
                :disabled="!formValid"
                type="submit"
                color="primary"
                height="58"
                class="full-w mt-5 font-weight-medium dark-blue"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from "axios";

export default {
  name: "ModalSoftSkills",

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "ok",
    },
    positions: {
      type: Array,
    },
    edit: {
      type: [Object, Boolean],
    },
  },
  data() {
    return {
      params: {
        page: 1,
        per_page: 100,
        type: "soft_skills",
        sortBy: null,
        sortDesc: false,
      },
      formLoading: false,
      formValid: false,
      formResponse: {},
      selectedSoftSkill: null,
      softSkills: [
        "Communication",
        "Teamwork",
        "Problem-solving",
        "Leadership",
        "Time Management",
      ],
      form: {
        admin_cms_id: null,
        rating: null,
        type: "soft_skills",
      },

      selectedExpertise: null,
      expertiseLevels: [
        { label: "Beginner", value: 1 },
        { label: "Intermediate", value: 2 },
        { label: "Expert", value: 3 },
      ],
    };
  },
  created() {
    this.initialize();
    this.$store.dispatch("admin/cmsFetchListType", this.params);
    if (this.edit) {
      this.populate();
    }
  },
  methods: {
    populate() {
      this.form.admin_cms_id = this.edit.admin_cms_id;
      this.form.rating = this.edit.rating;
    },
    initialize() {
      this.items = this.$store.getters["admin/cmsLists"][this.cms_type]?.items;
      this.params.type = this.params?.type;
    },
    close(type) {
      this.$emit("close", type);
    },
    save() {
      console.log("this form", this.form);
      if (!this.form.rating) {
        // Display error message or perform any necessary action
        this.formResponse = {
          error: true,
          message: "Please select an expertise level.",
        };
        return; // Prevent form submission
      }

      setTimeout(() => {
        this.formResponse = {};

        if (this.edit) {
          this.$store
            .dispatch("user/updateJobseekerSoftSkills", {
              id: this.edit.id,
              payload: this.form,
            })
            .then(() => {
              this.$emit("close", 1);
            })
            .catch((err) => {
              this.formResponse = err.data;
            })
            .finally(() => {
              this.formLoading = false;
            });
        } else {
          this.$store
            .dispatch("user/addJobseekerSkills", this.form)
            .then(() => {
              this.$emit("close", 1);
            })
            .catch((err) => {
              this.formResponse = err.data;
            })
            .finally(() => {
              this.formLoading = false;
            });
        }
      }, 100);

      // this.$emit("close", "ok");
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  padding-bottom: 0;
  padding-top: 0;
}
.dialog {
  font-family: $open-sans !important;
}
.wrap {
  padding: 30px;
  .header {
    font-size: 28px;
  }
  label {
    font-weight: 600 !important;
    color: #222 !important;
  }
}
</style>
