var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"dialog",attrs:{"persistent":"","width":"750px","max-width":"750px","overlay-color":"#0253B3","overlay-opacity":"0.3"},on:{"click:outside":function($event){return _vm.close('abort')}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{staticClass:"rounded-lg wrap onboarding-dialog",attrs:{"flat":""}},[_c('p',{staticClass:"text-left font-weight-bold mb-3 font-size-16 header mb-5"},[_vm._v(" Select Soft Skills and Expertise Level ")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.$store.getters['admin/cmsLists']['soft_skills'].items.map(
                function (item) {
                  return {
                    value: item.id,
                    text: item[this$1.$i18n.locale], // Fallback to item name if translation is not available
                  };
                }
              ),"label":"Soft Skills","rules":[_vm.validations.required],"outlined":"","append-icon":"mdi-chevron-down"},model:{value:(_vm.form.admin_cms_id),callback:function ($$v) {_vm.$set(_vm.form, "admin_cms_id", $$v)},expression:"form.admin_cms_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-rating',{attrs:{"items":_vm.expertiseLevels,"label":"Expertise Level","rules":[_vm.validations.required]},model:{value:(_vm.form.rating),callback:function ($$v) {_vm.$set(_vm.form, "rating", $$v)},expression:"form.rating"}})],1)],1),_c('ResponseAlert',{attrs:{"response":_vm.formResponse}}),_c('v-card-actions',{staticClass:"no-gutters pa-0 ma-0 mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"full-w mt-5 font-weight-medium ",attrs:{"height":"58","color":"#fff","light":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"full-w mt-5 font-weight-medium dark-blue",attrs:{"loading":_vm.formLoading,"disabled":!_vm.formValid,"type":"submit","color":"primary","height":"58"}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }