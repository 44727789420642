import { render, staticRenderFns } from "./ModalSoftSkills.vue?vue&type=template&id=6570a8ef&scoped=true&"
import script from "./ModalSoftSkills.vue?vue&type=script&lang=js&"
export * from "./ModalSoftSkills.vue?vue&type=script&lang=js&"
import style0 from "./ModalSoftSkills.vue?vue&type=style&index=0&id=6570a8ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6570a8ef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VCard,VCardActions,VCol,VDialog,VForm,VRating,VRow,VSelect})
